<template>
  <!-- Image and text  bg-transparent-->

<div>
  


    <div>
        <b-navbar toggleable="lg" type="dark"  class="fixed-top" style="background-color:#11508e;">
            <b-navbar-brand class="ml-5">
                 <router-link
                    class="waves-effect"
                    to="Repositorio"
                    active-class="active"
                >
                    <img
                    src="/img//icons/logo_emi.png"

                    width="200"
                    height="70"
                    alt=""
                    />
                </router-link>
            </b-navbar-brand>
            

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>


            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
                <b-nav-item  to="Repositorio">
                   <p class="text-white">
                       INICIO
                    </p> 
                   <!--  <router-link
                        class="waves-effect text-white"
                        to="Repositorio"
                        active-class="active"
                        >
                       
                    </router-link> -->
                </b-nav-item>
              
                <b-nav-item href="http://repositoriogrado.net.emi.edu.bo/" class="m-0,5"> 
                    <p class="text-white">
                        REPOSITORIO DE GRADO
                    </p>
                    
                </b-nav-item>

                <b-nav-item to="Login">
                    <p class="text-white">
                       LOGIN
                    </p>
                     
                     <!-- <router-link
                        class="waves-effect text-white"
                        to="Login"
                        active-class="active"
                        >
                        LOGIN
                    </router-link> -->
                </b-nav-item>

                <b-nav-item-dropdown right v-if="auth.id != null">
                    <!-- Using 'button-content' slot -->
                    <template #button-content>
                        <em>{{auth.Persona}}</em>
                    </template>
                    <b-dropdown-item >
                        <router-link
                            class="waves-effect text-blue"
                            to="Profile"
                            active-class="active"
                            >
                            Ver perfil
                        </router-link>
                    </b-dropdown-item>

                    <b-dropdown-item class="px-0">
                        <router-link
                            class="waves-effect text-blue px-0 mx-0"
                            to="Documento"
                            active-class="active"
                            >
                            Trabajos de Posgrado
                        </router-link>
                    </b-dropdown-item>
       

                    <b-dropdown-item @click="cerrarSesion">Cerrar Sesion</b-dropdown-item>
                </b-nav-item-dropdown>


                

            </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>

    <!-- component ver programa postgrado -->


        <!-- <VerProgramaPostgradoPage v-if="mostrarVerPrograma"/> -->
        <!-- =======Modal Login ========= -->
        <b-modal ref="modalLogin" size="md" title="TipoNivelAcademico">
            <h3 class="text-center m-t-0 m-b-15">
                <a href="#" class="logo logo-admin"><img src="/img/icons/icon-256x256.png" alt="" style="width:250px;"></a>
            </h3>
            <h4 class="text-center m-t-0 app-name">Inscripciones</h4>

           
                <div class="form-group">
                    <div class="col-12">
                        <input class="form-control" type="text" required="" name="email" placeholder="Correo Electrónico" v-model="loginCredenciales.email">
                        
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-12">
                        <input class="form-control" type="password" required="" name="password" placeholder="Contraseña" v-model="loginCredenciales.password">
                        
                    </div>
                </div>

                
                
                <div class="form-group text-center m-t-40">
                    <div class="col-12">
                        <button class="btn btn-primary btn-block btn-md waves-effect waves-light" type="submit" @click.prevent="login">Iniciar Sesión</button>
                    </div>
                </div>
                
                <hr>
                <div class="d-flex justify-content-center px-5 mt-3">
                    <p>Aun no estas registrado? Registrate <a @click.prevent="abrirModalRegistro" class="btn btn-info "> Aqui... </a></p>
                </div>
          

            <template v-slot:modal-footer>
                <div class="row">
                
                </div>
            </template>       
        </b-modal>
        <!-- =======Modal Login ========= -->

        <!-- =======Modal Registrarse ========= -->
        <b-modal ref="modalRegistro" size="md" title="RegistroDeUsuario">
            <template v-slot:modal-header>
                <h4>Formulario de Registro</h4>
            </template>
            <template v-slot:default>
                <div class="row">
                    <div class="form-group col-md-6">
                    <label for="Nombres"> Nombres </label>
                    <input  type="text" class="form-control text-uppercase" name="Nombres" v-model="nuevaPersona.Nombres"/>
                    <ul class="parsley-errors-list filled" id="parsley-id-19" v-if="errorBag.Nombres">
                        <li class="parsley-required">
                        {{ errorBag.Nombres }}
                        </li>
                    </ul>
                    </div>
                    <div class="form-group col-md-6">
                    <label for="ApellidoPaterno">Apellido Paterno</label>
                    <input type="text" class="form-control text-uppercase" name="ApellidoPaterno" v-model="nuevaPersona.ApellidoPaterno"/>
                    <ul class="parsley-errors-list filled" id="parsley-id-19" v-if="errorBag.ApellidoPaterno">
                        <li class="parsley-required">
                        {{ errorBag.ApellidoPaterno }}
                        </li>
                    </ul>
                    </div>
                    <div class="form-group col-md-6">
                    <label for="ApellidoMaterno"> Apellido Materno </label>
                    <input type="text" class="form-control text-uppercase" name="ApellidoMaterno" v-model="nuevaPersona.ApellidoMaterno"/>
                    <ul class="parsley-errors-list filled" id="parsley-id-19" v-if="errorBag.ApellidoMaterno">
                        <li class="parsley-required">
                        {{ errorBag.ApellidoMaterno }}
                        </li>
                    </ul>
                    </div>
                    <div class="form-group col-md-6 col-sm-6">
                    <label for="ApellidoMaterno"> Carnet de identidad </label>
                    <input type="text" class="form-control" v-model="nuevaPersona.CI"/>
                    <ul class="parsley-errors-list filled" id="parsley-id-19" v-if="errorBag.CI">
                        <li class="parsley-required">
                        {{ errorBag.CI }}
                        </li>
                    </ul>
                    </div>
                    <div class="form-group col-md-6 col-sm-6">
                    <label for="ApellidoMaterno"> Departamento </label>
                    <select class="form-control" v-model="nuevaPersona.Depto">
                        <option value="1">LP</option>
                        <option value="3">SC</option>
                        <option value="7">CB</option>
                        <option value="4">OR</option>
                        <option value="2">CH</option>
                        <option value="6">PT</option>
                        <option value="8">TJ</option>
                        <option value="5">BE</option>
                        <option value="9">PD</option>
                        <option value="10">RIB</option>
                        <option value="11">Extranjero</option>
                        <option value="12">Otro</option>
                    </select>
                    <ul class="parsley-errors-list filled" id="parsley-id-19" v-if="errorBag.Depto">
                        <li class="parsley-required">
                        {{ errorBag.Depto }}
                        </li>
                    </ul>
                    </div>
                    <div class="form-group col-md-6 col-sm-6">
                    <label > Estado Civil </label>
                    <select class="form-control" v-model="nuevaPersona.EstadoCivil">
                        <option value="Soltero/a">Soltero/a</option>
                        <option value="Casado/a">Casado/a</option>
                        <option value="Divorciado/a">Divorciado/a</option>
                        <option value="Viudo/a">Viudo/a</option>
                    </select>
                    <ul class="parsley-errors-list filled" id="parsley-id-19" v-if="errorBag.EstadoCivil">
                        <li class="parsley-required">
                        {{ errorBag.EstadoCivil }}
                        </li>
                    </ul>
                    </div>
                    <div class="form-group col-md-12">
                    <label for="ApellidoMaterno"> Nacionalidad </label>
                    <select class="form-control" v-model="nuevaPersona.Nacionalidad">
                        <option value="Boliviana">Boliviana</option>
                        <option value="Extranjero">Extranjero</option>
                    </select>
                    </div>
                </div>
                <div class="form-group ">
                    <label>Unidad Academica</label>
                    <select
                        type="text" class="form-control" v-model="nuevaPersona.UnidadAcademica">
                        <option :value="ua.id" v-for="ua in unidadAcademicas" v-bind:key="ua.id">
                        {{ ua.UnidadAcademica }}
                        </option>
                    </select>
                    <ul class="parsley-errors-list filled" id="parsley-id-19" v-if="errorBag.UnidadAcademica">
                        <li class="parsley-required">
                        {{ errorBag.UnidadAcademica }}
                        </li>
                    </ul>
                </div>
                <div class="row">
                    <div class="form-group col-md-6">
                    <label for="email"> Email </label>
                    <input
                        type="text"
                        class="form-control"
                        name="email"
                        v-model="nuevaPersona.email"
                    />
                    <ul
                        class="parsley-errors-list filled"
                        id="parsley-id-19"
                        v-if="errorBag.email"
                    >
                        <li class="parsley-required">
                        {{ errorBag.email }}
                        </li>
                    </ul>
                    </div>
                    <div class="form-group col-md-6">
                    <label for="password">Password </label>
                    <input
                        type="password"
                        class="form-control"
                        name="password"
                        v-model="nuevaPersona.password"
                    />
                    <ul
                        class="parsley-errors-list filled"
                        id="parsley-id-19"
                        v-if="errorBag.password"
                    >
                        <li class="parsley-required">
                        {{ errorBag.password }}
                        </li>
                    </ul>
                    </div>
                    <div class="form-group col-md-6">
                    <label> Fecha de nacimiento </label>
                    <input type="date" class="form-control" v-model="nuevaPersona.FechaNacimiento"/>
                    </div>
                    <div class="form-group col-md-6">
                    <label > Sexo </label>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="sexo" value="M" v-model="nuevaPersona.Sexo" >
                        <label class="form-check-label">Hombre</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="sexo" value="F" v-model="nuevaPersona.Sexo" >
                        <label class="form-check-label">Mujer</label>
                    </div>
                    </div>
                </div>
            </template>
            <template v-slot:modal-footer>
                <div class="row">
                    <!-- <a class="btn btn-warning m-2" @click="buscarSaga">Buscar Saga</a> -->
                    <a class="btn btn-danger m-2" @click="cancelPersona">Cancelar</a>
                    <!-- <a class="btn btn-success m-2" @click="buscarSaga">Guardar</a> -->
                    <a class="btn btn-success m-2" @click="verificarCorreo">Guardar</a>
                    <!-- <a class="btn btn-success m-2" @click="pruebacomponente">test</a> -->
                </div>
            </template>       
        </b-modal>
        <!-- =======Modal Registrarse ========= -->
</div>
    
  
  <!-- End Header -->
</template>

<script>
import MainService from "@/services/MainService.js";

export default {
    name: "MenuExterno",
  data() {
    
    return {
        ss : new MainService(),
        MenuExterno: {},
        errorBag: {},
        nuevaPersona:{},
        loginCredenciales: {},
        auth: {},
        unidadAcademicas:[],
        mostrarVerPrograma:false,
    };
  },

  
  mounted() {
    this.auth = JSON.parse(localStorage.getItem("persona"));
    console.log(this.auth);
    // this.getUnidadAcademica();
  },

    created(){
        this.$root.$refs.MenuExterno=this;
    },

	methods: {

		cancelPersona(){
			this.nuevaPersona = {};
			this.$refs['modalRegistro'].hide();
        },
        
        // pruebacomponente(){
        //     this.$root.$refs.VerProgramaPostgrado.prueba("sebas");
        // },

        pruebasofi(){
            console.log("holasofi");
        },

        

        verificarCorreo() {
            this.ss.verificarCorreo(this.nuevaPersona.email).then(
                (result) => {
                    // console.log(result);
                    let response = result.data;
                    console.log(response);
                    
                    if(response.success){
                        // console.log("el correo no existe");
                        this.buscarSaga();
                    }else{
                        // console.log("el correo existe");
                        this.$bvToast.toast(
                            'El correo ya se encuentra registrado',
                            {
                                title: 'Error',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    }
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Ocurrio un probela al verificar el correo',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        
		savePersona() {
            this.ss.storePersonaExterno(this.nuevaPersona).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
					)
					this.loginCredenciales.email = this.nuevaPersona.email;
					this.loginCredenciales.password = this.nuevaPersona.password;
					this.login();
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['modalRegistro'].hide();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
		},
		login() {
			this.ss.login(this.loginCredenciales)
				.then(
					(result) => {
						console.log(result);
						localStorage.setItem('persona', JSON.stringify(result.data.data));
						localStorage.setItem('token', JSON.stringify(result.data.token));
						console.log("logueado");
						this.login= true;
						this.$refs['modalLogin'].hide();
                        this.persona=result.data.data;
                        this.auth=result.data.data;
                        
                        this.loginCredenciales={};
						//this.$router.push('/');
						/*var persona = JSON.parse(localStorage.getItem('persona'));
						console.log(persona)*/
					}
				)
				.catch((error) => {
					this.errorBag = error.response.data.errors;
					this.$bvToast.toast(
						'Problema al Iniciar Sesion',
						{
							title: 'Error',
							variant: 'danger',
							autoHideDelay: 5000
						}
					)
				});
        },
        cerrarSesion() {
            this.auth={};
            localStorage.clear();
            this.$router.push("/repositorio");
            if (this.$msal.isAuthenticated()) {
                this.$msal.signOut();
            }
            this.$router.go(0);
        },
		abrirModalLogin(){
			// this.tiponivelacademico = {};
			this.$refs['modalLogin'].show();
			
        },
        abrirModaReg(){
			this.$refs['modalRegistro'].show();
			
		},
		abrirModalRegistro(){
			// this.tiponivelacademico = {};
			this.$refs['modalLogin'].hide();
            this.$refs['modalRegistro'].show();
        },
        // getUnidadAcademica() {
        //     this.ss.listExternoUnidadAcademica().then(
        //         (result) => {
        //             let response = result.data;
        //             // this.unidadAcademicas = response.data;
        //             let uas=response.data
        //             this.unidadAcademicas = uas.splice(1, 4);
        //         }
        //     ).catch(error => {
        //         console.log(error);
        //     });
        // },

        buscarSaga() {
			let params = {
				CI: this.nuevaPersona.CI
			}
            this.ss.buscarSaga(params)
            .then(
                (result) => {
                    let response = result.data;
                    //Si encuentra al usuario...
                    if(response.success == true){
                        this.nuevaPersona.CodigoSaga = response.data.CodAlumno;
                        this.nuevaPersona.correoInstitucional = response.data.email_emi;
                        this.nuevaPersona.Celular = response.data.celular;
                        this.nuevaPersona.Telefono = response.data.Telefono;
                        this.nuevaPersona.Direccion = response.data.Direccion;
                    }
                    this.savePersona();
                    console.log(response);
                }
            )
            .catch(error => {
                console.log(error);
            });
		},
    },


};
</script>

<style scoped>
.nombreLogin{
    padding: 10px 0 10px 25px;
    color: rgb(32, 108, 223);
}

</style>
